import { createError, useFetch } from '#app';
import { StorageSerializers, useSessionStorage } from '@vueuse/core';

export default async <T>(url: string) => {
  const cached = useSessionStorage<{ data: T, ttl: number }>(url, null, {
    serializer: StorageSerializers.object,
  });

  if (!cached.value || cached.value.ttl <= Date.now() - (3 * 1e3 * 60)) {
    const { data, error } = await useFetch<T>(url);

    if (error.value) {
      throw createError({
        ...error.value,
        statusMessage: `No se pudo sacar info de ${url}`,
      });
    }

    cached.value = { 
      data: data.value as T,
      ttl: Date.now()
    };
  } else {
    console.log(`Obteniendo valor desde cache ${url}`);
  }

  return cached
}