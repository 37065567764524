import { defineStore } from 'pinia';
import PouchDB from 'pouchdb';
import { ref, computed } from 'vue';
import { useNuxtApp } from '#app';

import type { Tarjeta, TarjetaDB } from '@/interfaces';
import useFetchWithCache from '~/composables/useFetchWithCache';

let Tarjetas: null | PouchDB.Database<{ value: Tarjeta }>;
export interface IStateTarjetas {
  tarjetas: TarjetaDB[];
  agregando: boolean;
  actualizando: boolean;
  cargando: boolean;
  borrando: boolean;
}

export const useTarjetaStore = defineStore('tarjetas', () => {
  const { $db } = useNuxtApp();
  
  const tarjetas = ref<TarjetaDB[]>([]);
  const agregando = ref(false);
  const actualizando = ref(false);
  const cargando = ref(true);
  const borrando = ref(false);

  const principal = computed<undefined | TarjetaDB>(() => {
    let existePrincipal: null | TarjetaDB = null;

    tarjetas.value?.forEach((tarjeta) => {
      if (tarjeta?.value.principal) {
        existePrincipal = tarjeta;
      }
    });

    return existePrincipal || tarjetas.value[0];
  })

  function initTarjetas() {
    Tarjetas = $db.Tarjetas;
    return getTarjetas();
  }

  async function fetchSaldoAPI(tarjetaId: string) {
    if (actualizando.value) throw new Error('Ya esta actualizando');
    actualizando.value = true;

    return useFetchWithCache<Tarjeta>(`/api/pregunta-saldo/${tarjetaId}`)
      .then((data) => {
        if (!data.value?.data?.numero_tarjeta || !data.value?.data?.saldo) {
          return Promise.reject(
            new Error(`Tarjeta ${tarjetaId} no encontrada`),
          );
        }

        return data.value.data;
      })
      .finally(() => {
        actualizando.value = false;
      });
  }

  async function getTarjetas() {
    if (!Tarjetas) await initTarjetas();

    return Tarjetas?.allDocs({ include_docs: true }).then(({ rows }) =>
      rows.map((row) => row.doc),
    ).then((trjs: TarjetaDB[]) => {
      if (trjs) {
        tarjetas.value = trjs;
        cargando.value = false;
  
        return trjs;
      }
    });
  }
  
  function getTarjeta(tarjetaId: string) {
    return Tarjetas?.get(tarjetaId) || Promise.reject(new Error('Tarjetas no inicializadas'));
  }

  async function quitarTarjeta(tarjetaId: string | number) {
    if (!tarjetaId) throw new Error('Código tarjeta inválido: ' + tarjetaId);
    if (borrando.value) throw new Error('Ya esta borrando');
    borrando.value = true;

    const tarjeta = await Tarjetas?.get(String(tarjetaId)).catch(e => null);
    if (!tarjeta) throw new Error('Tarjeta no encontrada');

    await Tarjetas?.remove(tarjeta._id, tarjeta._rev).catch(e => console.log(e));
    const tarjetasNew = await getTarjetas();

    if (tarjetasNew) {
      tarjetas.value = tarjetasNew;
    }
    borrando.value = false;
  }

  async function setTarjeta({ _id, value }: { _id: string, value: Tarjeta }) {
    if (!_id) throw new Error('Id tarjeta no encontrado');
    if (agregando.value) throw new Error('Ya se esta agregando la tarjeta');
    agregando.value = true;

    const tarjeta = await Tarjetas?.get(_id).catch(e => ({}));
    await Tarjetas?.put(Object.assign({}, tarjeta, { _id, value }));

    const newtarjeta = await Tarjetas?.get(_id);

    agregando.value = false;

    return newtarjeta;
  }

  async function fetchSaldo(idtarjeta?: string | undefined, nombre?: string) {
    if (!Tarjetas) await initTarjetas();
    let tarjetaId = idtarjeta || (principal.value || {})._id;

    if (!tarjetaId) {
      return false;
    }

    const saldo: Tarjeta = await fetchSaldoAPI(tarjetaId)
      .catch(e => ({} as Tarjeta));

      
    const tarjeta = await getTarjeta(tarjetaId)
      .catch(() => ({ value: { nombre: nombre || '', numero_tarjeta: tarjetaId }, _id: tarjetaId }));
      
    await setTarjeta({
      _id: tarjeta._id || '',
      value: Object.assign({}, tarjeta.value, saldo, nombre ? { nombre } : {}),
    })
      .catch(e => console.log(e));
    
    const newtarjetas = await getTarjetas();

    if (newtarjetas?.[0]) {
      tarjetas.value = [newtarjetas?.[0]];
    }
    cargando.value = false;
  }

  return {
    // State
    tarjetas,
    agregando,
    actualizando,
    cargando,
    borrando,

    // Actions
    initTarjetas,
    fetchSaldoAPI,
    getTarjetas,
    getTarjeta,
    quitarTarjeta,
    setTarjeta,
    fetchSaldo,
    
    // Getters
    principal,
  }
});